import { createVuestic } from 'vuestic-ui';
import { createApp } from 'vue'

import App from './App.vue'

import './registerServiceWorker'
import router from './router'

import { createPinia } from 'pinia'

import vuesticGlobalConfig from './services/vuestic-ui/global-config'

import uuid from 'vue-uuid'

// @ts-ignore
import VueQrcodeReader from "vue3-qrcode-reader";

import i18n from './i18n'

// import '../node_modules/vue-cookieconsent/vendor/cookieconsent.css'

import CookieConsentPlugin from './plugins/CookieConsent/';
import './plugins/CookieConsent/vendor/cookieconsent.css'
import cookieConfig from './components/cookie/config'

import VueCookies from 'vue-cookies'

import { createHead } from "@vueuse/head"

const app = createApp(App)
const pinia = createPinia()
const head = createHead()

app.use(router)
app.use(uuid)
app.use(VueQrcodeReader)
app.use(pinia)
app.use(i18n)
app.use(head)
// app.use(CookieConsent, cookieConfig)
app.use(CookieConsentPlugin, cookieConfig)
app.use(VueCookies, { expires: '30d'})
app.use(createVuestic({config: vuesticGlobalConfig}))
app.mount('#app')