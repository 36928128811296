<template>
  <div :class="{
    'fullscreen-layout': true
  }">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'fullscreen-layout',

  components: { },

  setup() {
    return { }
  },

  beforeCreate () { },

  methods: {  },

  mounted() { },
}
</script>

<style lang="scss">
$mobileBreakPointPX: 640px;
$tabletBreakPointPX: 768px;
</style>
