// Pinia Store
import { defineStore } from 'pinia'

import axios from 'axios'

interface State {
  isSidebarMinimized: boolean
}

export const useSidebarStore = defineStore('sidebar', {
  state: (): State => ({
    isSidebarMinimized: false,
  }),

  actions: {
    // MUTATIONS
    updateSidebarCollapsedState(isSidebarMinimized:boolean) {
      this.isSidebarMinimized = isSidebarMinimized
    },

    // ACTIONS

  },

  getters: { },
});
