/* eslint-disable @typescript-eslint/camelcase */

export default {
  current_lang: 'en',
  autoclear_cookies: false,                   // default: false
  page_scripts: true,                        // default: false

  mode: 'opt-in',                             // default: 'opt-in'; value: 'opt-in' or 'opt-out'
  delay: 0,                                   // default: 0
  auto_language: null,                        // default: null; could also be 'browser' or 'document'
  autorun: false,                              // default: true
  force_consent: false,                       // default: false
  hide_from_bots: true,                       // default: false
  remove_cookie_tables: false,                // default: false
  cookie_name: 'cc_cookie',                   // default: 'cc_cookie'
  cookie_expiration: 182,                     // default: 182 (days)
  cookie_necessary_only_expiration: 182,      // default: disabled
  cookie_domain: location.hostname,           // default: current domain
  cookie_path: '/',                           // default: root
  cookie_same_site: 'Lax',                    // default: 'Lax'
  use_rfc_cookie: false,                      // default: false
  revision: 0,                                // default: 0

  gui_options: {
    consent_modal: {
      layout: 'box',                          // box/cloud/bar
      position: 'bottom right',               // bottom/middle/top + left/right/center
      transition: 'slide',                    // zoom/slide
      swap_buttons: true                      // enable to invert buttons
    },
    settings_modal: {
      layout: 'box',                          // box/bar
      // position: 'left',                    // left/right
      transition: 'slide',                    // zoom/slide
      swap_buttons: true
    }
  },

  onFirstAction: function(user_preferences, cookie){
    // callback triggered only once
    window.dispatchEvent(new Event('consentChanged'))
  },

  onAccept: function (cookie) {
    window.dispatchEvent(new Event('consentChanged'))
  },

  onChange: function (cookie, changed_preferences) {
    window.dispatchEvent(new Event('consentChanged'))
  },

  languages: {
    'en': {
      consent_modal: {
        title: 'We use cookies!',
        description: 'Hi, this website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only after consent. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
        primary_btn: {
          text: 'Accept all',
          role: 'accept_all'              // 'accept_selected' or 'accept_all'
        },
        secondary_btn: {
          text: 'Reject all',
          role: 'accept_necessary'        // 'settings' or 'accept_necessary'
        }
      },
      settings_modal: {
        title: 'Cookie preferences',
        save_settings_btn: 'Save settings',
        accept_all_btn: 'Accept all',
        reject_all_btn: 'Reject all',
        close_btn_label: 'Close',
        cookie_table_headers: [
          {col1: 'Name'},
          {col2: 'Domain'},
          {col3: 'Expiration'},
          {col4: 'Description'}
        ],
        blocks: [
          {
            title: 'Cookie usage 📢',
            description: 'I use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a href="/en/privacy-policy" class="cc-link">privacy policy</a>.'
          },
          {
            title: 'Strictly necessary cookies',
            description: 'These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly',
            toggle: {
              value: 'necessary',
              enabled: true,
              readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
            }
          },
          {
            title: 'Social Media Content and Marketing',
            description: 'These cookies allow the website to remember the choices you have made in the past',
            toggle: {
              value: 'socialmedia',     // your cookie category
              enabled: false,
              readonly: false
            },
            cookie_table: [             // list of all expected cookies
              {
                col1: 'csrftoken',
                col2: '.instagram.com',
                col3: '1 year',
                col4: '<a target="_blank" href="https://help.instagram.com/1896641480634370">Learn more</a>',
              },
              {
                col1: 'datr',
                col2: '.instagram.com',
                col3: '2 years',
                col4: '<a target="_blank" href="https://help.instagram.com/1896641480634370">Learn more</a>',
              },
              {
                col1: 'ds_user_id',
                col2: '.instagram.com',
                col3: '60 days',
                col4: '<a target="_blank" href="https://help.instagram.com/1896641480634370">Learn more</a>',
              },
              {
                col1: 'ig_did',
                col2: '.instagram.com',
                col3: '2 years',
                col4: '<a target="_blank" href="https://help.instagram.com/1896641480634370">Learn more</a>',
              },
              {
                col1: 'mid',
                col2: '.instagram.com',
                col3: '60 days',
                col4: '<a target="_blank" href="https://help.instagram.com/1896641480634370">Learn more</a>',
              },
              {
                col1: 'rur',
                col2: '.instagram.com',
                col3: 'Session',
                col4: '<a target="_blank" href="https://help.instagram.com/1896641480634370">Learn more</a>',
              },
              {
                col1: 'sessionid',
                col2: '.instagram.com',
                col3: '1 year',
                col4: '<a target="_blank" href="https://help.instagram.com/1896641480634370">Learn more</a>',
              },
              {
                col1: 'shbid',
                col2: '.instagram.com',
                col3: '7 days',
                col4: '<a target="_blank" href="https://help.instagram.com/1896641480634370">Learn more</a>',
              },
            ]
          },
          /*
          {
            title: 'Performance and Analytics cookies',
            description: 'These cookies allow the website to remember the choices you have made in the past',
            toggle: {
              value: 'analytics',     // your cookie category
              enabled: false,
              readonly: false
            },
            cookie_table: [             // list of all expected cookies
              {
                col1: '^_ga',       // match all cookies starting with "_ga"
                col2: 'google.com',
                col3: '2 years',
                col4: 'description ...',
                is_regex: true
              },
              {
                col1: '_gid',
                col2: 'google.com',
                col3: '1 day',
                col4: 'description ...',
              }
            ]
          },
          /*
          {
            title: 'Advertisement and Targeting cookies',
            description: 'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you',
            toggle: {
              value: 'targeting',
              enabled: false,
              readonly: false
            }
          },
          */
          {
            title: 'More information',
            description: 'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="/en/contact">contact us</a>.',
          }
        ]
      }
    },
    'de': {
      consent_modal: {
        title: 'Wir benutzen Cookies!',
        description: 'Hallo, diese Website verwendet essentielle Cookies, um ihren ordnungsgemäßen Betrieb zu gewährleisten, und Tracking-Cookies, um zu verstehen, wie Sie mit ihr interagieren. Letztere werden nur nach Zustimmung gesetzt werden. <button type="button" data-cc="c-settings" class="cc-link">Selbst auswählen</button>',
        primary_btn: {
          text: 'Alle akzeptieren',
          role: 'accept_all'              // 'accept_selected' or 'accept_all'
        },
        secondary_btn: {
          text: 'Einstellungen',
          role: 'settings'        // 'settings' or 'accept_necessary'
        }
      },
      settings_modal: {
        title: 'Cookie-Einstellungen',
        save_settings_btn: 'Einstellungen speichern',
        accept_all_btn: 'Alle akzeptieren',
        reject_all_btn: 'Alle ablehnen',
        close_btn_label: 'Schließen',
        cookie_table_headers: [
          {col1: 'Name'},
          {col2: 'Domain'},
          {col3: 'Expiration'},
          {col4: 'Description'}
        ],
        blocks: [
          {
            title: 'Verwendung von Cookies 📢',
            description: 'Diese Website benutzt Cookies, um die grundlegenden Funktionen der Website zu gewährleisten und um Ihr Online-Erlebnis zu verbessern. Sie können für jede Kategorie wählen, ob Sie sich ein- oder austragen möchten. Für weitere Einzelheiten zu Cookies und anderen sensiblen Daten lesen Sie bitte die vollständige <a href="/de/datenschutz" class="cc-link">Datenschutzbestimmungen</a>.'
          },
          {
            title: 'Notwendige Cookies',
            description: 'Diese Cookies sind für das ordnungsgemäße Funktionieren dieser Website unerlässlich. Ohne diese Cookies würde die Website nicht richtig funktionieren.',
            toggle: {
              value: 'necessary',
              enabled: true,
              readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
            }
          },
          {
            title: 'Social Media Content und Marketing',
            description: 'Diese Cookies ermöglichen es der Website, sich an die von Ihnen in der Vergangenheit getroffenen Auswahlen zu erinnern',
            toggle: {
              value: 'socialmedia',     // your cookie category
              enabled: false,
              readonly: false
            },
            cookie_table: [             // list of all expected cookies
              {
                col1: 'csrftoken',
                col2: '.instagram.com',
                col3: '1 year',
                col4: '<a target="_blank" href="https://help.instagram.com/1896641480634370">Mehr erfahren</a>',
              },
              {
                col1: 'datr',
                col2: '.instagram.com',
                col3: '2 years',
                col4: '<a target="_blank" href="https://help.instagram.com/1896641480634370">Mehr erfahren</a>',
              },
              {
                col1: 'ds_user_id',
                col2: '.instagram.com',
                col3: '60 days',
                col4: '<a target="_blank" href="https://help.instagram.com/1896641480634370">Mehr erfahren</a>',
              },
              {
                col1: 'ig_did',
                col2: '.instagram.com',
                col3: '2 years',
                col4: '<a target="_blank" href="https://help.instagram.com/1896641480634370">Mehr erfahren</a>',
              },
              {
                col1: 'mid',
                col2: '.instagram.com',
                col3: '60 days',
                col4: '<a target="_blank" href="https://help.instagram.com/1896641480634370">Mehr erfahren</a>',
              },
              {
                col1: 'rur',
                col2: '.instagram.com',
                col3: 'Session',
                col4: '<a target="_blank" href="https://help.instagram.com/1896641480634370">Mehr erfahren</a>',
              },
              {
                col1: 'sessionid',
                col2: '.instagram.com',
                col3: '1 year',
                col4: '<a target="_blank" href="https://help.instagram.com/1896641480634370">Mehr erfahren</a>',
              },
              {
                col1: 'shbid',
                col2: '.instagram.com',
                col3: '7 days',
                col4: '<a target="_blank" href="https://help.instagram.com/1896641480634370">Mehr erfahren</a>',
              },
            ]
          },
          /*
          {
            title: 'Performance and Analytics cookies',
            description: 'These cookies allow the website to remember the choices you have made in the past',
            toggle: {
              value: 'analytics',     // your cookie category
              enabled: false,
              readonly: false
            },
            cookie_table: [             // list of all expected cookies
              {
                col1: '^_ga',       // match all cookies starting with "_ga"
                col2: 'google.com',
                col3: '2 years',
                col4: 'description ...',
                is_regex: true
              },
              {
                col1: '_gid',
                col2: 'google.com',
                col3: '1 day',
                col4: 'description ...',
              }
            ]
          },
          {
            title: 'Advertisement and Targeting cookies',
            description: 'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you',
            toggle: {
              value: 'targeting',
              enabled: false,
              readonly: false
            }
          },
          */
          {
            title: 'Weitere Informationen',
            description: 'Bei Fragen zu unserer Politik in Bezug auf Cookies und Ihre Wahlmöglichkeiten wenden Sie sich bitte an <a class="cc-link" href="/de/kontakt">Kontakt</a>.',
          }
        ]
      }
    }
  }
}
