import axios from 'axios';

import authHeader from './auth-header'

class MediaService {
  getActive() {
    return axios.get(import.meta.env.VITE_APP_API_URL + '/media/active');
  }

  getAll() {
    const auth_header = authHeader();

    return axios.get(import.meta.env.VITE_APP_API_URL + '/media/all', {headers: auth_header});
  }

  create(item:any) {
    const auth_header = authHeader();

    let body = Object.assign({}, item);

    delete body['_id'];

    return axios.post(import.meta.env.VITE_APP_API_URL + '/media', body, {headers: auth_header})
  }

  update(item:any) {
    const auth_header = authHeader();

    let body = Object.assign({}, item);

    delete body['_id'];

    return axios.patch(import.meta.env.VITE_APP_API_URL + '/media/'+item._id, body, {headers: auth_header});
  }

  delete(id:any) {
    const auth_header = authHeader();

    return axios.delete(import.meta.env.VITE_APP_API_URL + '/media/'+id, {headers: auth_header});
  }
}
export default new MediaService();
