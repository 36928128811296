<template>
  <div class="footer">
    <div class="footer-container">
      <Partners />
    </div>

    <div class="footer-content">
      <div class="footer-content-box">
        <div class="footer-content-box-item">
          <img src="/logo_white.svg" alt="Logo"/>

          <!--
          <img v-if="logo_white" :src="logo_white" alt="Logo"/>
          -->
        </div>

        <div class="footer-content-box-item flex-column">
          <div class="footer-content-box-item-text">© {{ new Date().getFullYear() }} - Tennis-Club Bruchköbel e.V.</div>
          <div class="footer-content-box-item-text"><a :href="$t(`itf.footer.imprint_link`)">{{ $t(`itf.footer.imprint`) }}</a></div>
          <div class="footer-content-box-item-text"><a :href="$t(`itf.footer.privacy_policy_link`)">{{ $t(`itf.footer.privacy_policy`) }}</a></div>
          <div class="footer-content-box-item-text"><a :href="$t(`itf.footer.contact_link`)">{{ $t(`itf.footer.contact`) }}</a></div>
        </div>

        <div class="footer-content-box-item flex-column">
          <div class="footer-content-box-item-text" style="font-size: 16px; font-family: Lato-Bold">{{ $t('footer.languages') }}</div>
          <div class="footer-content-box-item-text"><a href="/de">Deutsch</a></div>
          <div class="footer-content-box-item-text"><a href="/en">English</a></div>
        </div>

        <div class="footer-content-box-item flex-column">
          <div class="footer-content-box-item-text" style="font-size: 16px; font-family: Lato-Bold">{{ $t('footer.social_media') }}</div>

          <a style="display: flex; gap: 4px; align-items: center; margin-top: 4px" href="https://www.instagram.com/itf.bruchkoebel" target="_blank">
            <img style="height: 32px !important; fill: #fff;" src="/img/icons/instagram.svg" />
            <div class="footer-content-box-item-text">@itf.bruchkoebel</div>
          </a>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { defineComponent } from 'vue';

import Partners from '../partners/Partners.vue'

import mediaService from '@/services/media.service';

export default defineComponent({
  name: 'Footer',
  components: { Partners },
  props: [ ],

  data () {
    return {
      logo_white: null,
    }
  },

  methods: {
    getMedia () {
      mediaService.getActive()
        .then((data) => {
          this.logo_white = import.meta.env.VITE_APP_API_URL + data.data.media_logo_white.url;
        }).catch((error) => { });
    },
  },

  mounted () {
    this.getMedia();
  }
});
</script>

<style lang="scss" scoped>
  .flex-column {
    flex-direction: column;
    justify-content: left;
    align-items: center;
  }

  .footer {
    position: relative;

    &-container {
      max-width: 1280px;
      width: 100%;
      margin: 0 auto;
      margin-top: 80px;
      padding-left: 45px;
      padding-right: 45px;

      @media (max-width: 620px) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    &-content {
      margin-top: 12px;
      background: #33335f;
      min-height: 100px;
      padding-top: 45px;
      padding-bottom: 45px;

      &-box {
        max-width: 1280px;
        width: 100%;
        margin: 0 auto;
        padding-left: 45px;
        padding-right: 45px;

        display: flex;

        @media (max-width: 900px) {
          flex-direction: column;
          padding-left: 20px;
          padding-right: 20px;
        }

        &-item {
          display: flex;
          align-items: center;
          border-right: 1px solid #fff;
          padding: 10px 40px;

          img {
            height: 100px;
          }

          &-text {
            display: flex;
            width: 100%;
            color: #fff;
            font-size: 14px;
            line-height: 24px;

            text-align: left;

            a {
              color: #fff;
              text-decoration: underline
            }
          }
        }
      }
    }
  }
</style>
