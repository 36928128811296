import axios from 'axios';

import authHeader from './auth-header'

class CMSService {
  getById(id:any, lang:string) {
    return axios.get(import.meta.env.VITE_APP_API_URL + '/cms/article/' + id + '?lang=' + lang);
  }

  getByRelLink(rel_link:any, lang:string) {
    return axios.get(import.meta.env.VITE_APP_API_URL + '/cms/article/rel_link/' + rel_link + '?lang=' + lang);
  }

  getAllCategory(category:any, lang:string) {
    return axios.get(import.meta.env.VITE_APP_API_URL + '/cms/article/category/' + category + '?lang=' + lang);
  }

  getArticlesByFilter(page:number, limit:number, tags:Array<string>, categories:Array<string>, lang:Array<string>) {
    let query = '';

    if(tags.length > 0) {
      query += '&tags=' + tags.join(',')
    }

    if(categories.length > 0) {
      query += '&category=' + tags.join(',')
    }

    if(lang.length > 0) {
      query += '&lang=' + lang.join(',')
    }

    return axios.get(import.meta.env.VITE_APP_API_URL + `/cms/article/filter?page=${page}&limit=${limit}${query}`);
  }
}
export default new CMSService();
