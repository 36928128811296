// Pinia Store
import { defineStore } from 'pinia'

interface State {
  active: Boolean
}

export const useModalStore = defineStore('modal', {
  state: (): State => ({
    active: false,
  }),

  actions: {
    // MUTATIONS
    MODAL_ACTIVE(){
      this.active = true;
    },

    MODAL_INACTIVE() {
      this.active = false;
    },

    // ACTIONS
    ACTIVE() {
      return new Promise((resolve, reject) => {
        this.MODAL_ACTIVE()

        document.body.classList.add('amvs-modal-active')

        resolve(this.active)
      })
    },

    INACTIVE() {
      return new Promise((resolve, reject) => {
        this.MODAL_INACTIVE()

        document.body.classList.remove('amvs-modal-active')

        resolve(this.active)
      })
    },
  },

  getters: {
    isActive: (state:any) => !!state.active,
  },
});
