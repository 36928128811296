<template>
  <div :class="{
    'site-layout': true,
    'modal-active': modalIsActive
  }">
    <SiteNavbar />
      <div class="site-layout__content">
        <div class="site-layout__sidebar-wrapper" :class="{ minimized: isSidebarMinimized }">
        </div>
        <div class="site-layout__page">
          <div class="layout fluid gutter--xl">
            <router-view/>
          </div>
        </div>
      </div>
    <SiteFooter />

    <div @click="show_cookie()" v-if="windowTop > 120" class="cookie-show">
      <i class="fa-solid fa-cookie-bite"></i>
    </div>
  </div>
</template>

<script>
import { useSidebarStore } from '@/stores/sidebar.store'
import { useModalStore } from '@/stores/modal.store'

import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import { onBeforeRouteUpdate } from 'vue-router';

import SiteNavbar from '@/components/navbar/SiteNavbar.vue'
import CookieConfig from '@/components/cookie/config'

import SiteFooter from '@/components/footer/SiteFooter.vue'

import trackService from '@/services/track.service'

import { uuid } from 'vue-uuid'

export default {
  name: 'site-layout',

  components: {
    SiteNavbar, SiteFooter
  },

  setup() {
    const sidebarStore = useSidebarStore();
    const modalStore = useModalStore();

    const mobileBreakPointPX = 640
    const tabletBreakPointPX = 768

    const sidebarWidth = ref('16rem')
    const sidebarMinimizedWidth = ref(undefined)

    const isMobile = ref(false)
    const isTablet = ref(false)
    const isSidebarMinimized = computed(() => sidebarStore.isSidebarMinimized)
    const checkIsTablet = () => window.innerWidth <= tabletBreakPointPX
    const checkIsMobile = () => window.innerWidth <= mobileBreakPointPX

    const modalIsActive = computed(() => modalStore.active)

    const windowTop = ref(0)

    const onResize = () => {
      sidebarStore.updateSidebarCollapsedState(checkIsTablet())

      isMobile.value = checkIsMobile()
      isTablet.value = checkIsTablet()
      sidebarMinimizedWidth.value = isMobile.value ? '0' : '4rem'
      sidebarWidth.value = isTablet.value ? '100%' : '16rem'
    }

    onMounted(() => {
      window.addEventListener('resize', onResize)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('resize', onResize)
    })

    onBeforeRouteUpdate(() => {
      if (checkIsTablet()) {
        // Collapse sidebar after route change for Mobile
        sidebarStore.updateSidebarCollapsedState(true)
      }
    })

    onResize()

    const isFullScreenSidebar = computed(() => isTablet.value && !isSidebarMinimized.value)

    const onCloseSidebarButtonClick = () => {
      sidebarStore.updateSidebarCollapsedState(true)
    }

    return {
      windowTop,
      sidebarStore,
      isSidebarMinimized,
      sidebarWidth, sidebarMinimizedWidth,
      isFullScreenSidebar, onCloseSidebarButtonClick,
      modalIsActive
    }
  },

  beforeCreate () {
    this.$cc.on('consent-changed', () => {
      // this.$cc.getUserPreferences()
    })

    if(!this.$cookies.get('visitor_id') || this.$cookies.get('visitor_id') == undefined || this.$cookies.get('visitor_id') == 'undefined') {
      this.$cookies.set('visitor_id', uuid.v4())
    }

    trackService.track(window.location.href, this.$cookies.get('visitor_id'))
  },

  methods: {
    show_cookie () {
      this.$cc.showSettings()
      // console.log(this.$cc.getUserPreferences())
    },

    onScroll(e) {
      this.windowTop = window.top.scrollY /* or: e.target.documentElement.scrollTop */
    }
  },

  mounted() {
    let cookie_config = Object.assign({}, CookieConfig);
    cookie_config['current_lang'] = this.$i18n.locale;

    this.$cc.run(cookie_config);
    this.$cc.show();

    window.addEventListener("scroll", this.onScroll)
  },
}
</script>

<style lang="scss">
$mobileBreakPointPX: 640px;
$tabletBreakPointPX: 768px;

.cookie-show {
  width: 46px;
  height: 46px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #33335f;
  color: #fff;

  position: fixed;

  bottom: 12px;
  right: 12px;

  font-size: 32px;

  border-radius: 8px;
  border: 1px solid #fff;

  cursor: pointer;

  z-index: 9999;

  &:hover {
    background-color: var(--va-primary);
  }
}

.site-layout {
  height: auto;
  display: flex;
  flex-direction: column;

  @media(max-width: 567px) {
    &.modal-active {
      max-height: 100px;
      overflow: hidden;
    }
  }

  &__navbar {
    min-height: 4rem;
  }

  &__content {
    display: flex;
    // height: calc(100vh - 4rem);
    height: auto;
    flex: 1;

    .site-layout__sidebar-wrapper {
      position: relative;
      height: 100%;
      background: var(--va-white);

      @media screen and (max-width: $tabletBreakPointPX) {
        &:not(.minimized) {
          width: 100%;
          height: 100%;
          position: fixed;
          top: 0;
          z-index: 999;
        }

        .va-sidebar:not(.va-sidebar--minimized) {
          // Z-index fix for preventing overflow for close button
          z-index: -1;
          .va-sidebar__menu {
            padding: 0;
          }
        }
      }
    }
  }

  &__page {
    flex-grow: 2;
    overflow-y: auto;

    max-width: 1280px;
    margin: 0 auto;
    min-height: calc(100vh - 362px);

    .layout {
      padding-left: 45px;
      padding-right: 45px;

      @media (max-width: 620px) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
}
</style>
