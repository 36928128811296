// Pinia Store
import { defineStore, StateTree } from 'pinia'

import axios from 'axios'
import authService from '@/services/auth.service'

import CryptoJS  from 'crypto-js';

interface State {
  success: string
  token: string
  user: User | null
  status: string
}

interface User {
  email: string
  role: Role
}

interface Role {
  name: string
  permissions: Permission[]
}

interface Permission {
  name: string
}

const getPerists = (key: any): any => {
  let pre_value = localStorage.getItem(key) ? localStorage.getItem(key) : null;

  if(!pre_value) {
    return null;
  }

  let decrypted_value = CryptoJS.AES.decrypt(pre_value, import.meta.env.VITE_APP_STORE_SECRET ? import.meta.env.VITE_APP_STORE_SECRET : 'SECRET').toString(CryptoJS.enc.Utf8);

  return JSON.parse(decrypted_value || '{}');
}

const setPerists = (key: any, value: any) => {
  let _value = JSON.stringify(value)
  let encrypted_value = CryptoJS.AES.encrypt(_value, import.meta.env.VITE_APP_STORE_SECRET ? import.meta.env.VITE_APP_STORE_SECRET : 'SECRET').toString()
  localStorage.setItem(key, encrypted_value);
}

export const useAuthStore = defineStore('auth', {
  state: (): State => ({
    success: '',
    token: getPerists('token'),
    user: getPerists('user'),
    status: '',
  }),

  actions: {
    // MUTATIONS
    AUTH_REQUEST(){
      this.status = 'loading'
    },

    AUTH_SUCCESS(user_and_token:any) {
      this.status = 'success';
      this.token = user_and_token.token;
      this.user = user_and_token.user;

      setPerists('user', this.user);
      setPerists('token', this.token);
    },

    AUTH_ERROR() {
      this.status = 'error';
    },

    AUTH_LOGOUT() {
      this.status = '';
      this.token = '';
    },

    // ACTIONS
    LOGIN(user:any) {
      return new Promise((resolve, reject) => {
        // commit('AUTH_REQUEST');
        this.AUTH_REQUEST()

        authService.login(user.email, user.password).then((data) => {
          const token = data.token;
          const user  = data.user;

          axios.defaults.headers.common['Authorization'] = "Bearer" + token;
          this.AUTH_SUCCESS({token:token, user:user});

          resolve(data);
        }).catch(err => {
          this.AUTH_ERROR();
          localStorage.removeItem('token');
          localStorage.removeItem('user');

          reject(err);
        })
      })
    },

    LOGOUT() {
      return new Promise((resolve) => {
        authService.logout().then(() => {
          this.AUTH_LOGOUT();
          delete axios.defaults.headers.common['Authorization'];
          resolve(true);
        })
      })
    }
  },

  getters: {
    isLoggedIn: (state:any) => !!state.token,
    authStatus: (state:any) => state.status,
  },
});
