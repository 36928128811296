import { createI18n } from 'vue-i18n'

import en from './locales/en.json'
import de from './locales/de.json'

const i18nConfig = {
  locale: 'en',
  fallbackLocale: import.meta.env.VITE_APP_I18N_FALLBACK_LOCALE || 'en',
  globalInjection: true,
  messages: {
    en,
    de
  }
}

export default createI18n(i18nConfig)
