import VaIcon from './components/va-icon'
import VaToast from './components/va-toast'
import VaButton from './components/va-button'
import iconsConfig from './icons-config/icons-config'
import { THEMES } from './themes'

export default {
  colors: {
    presets: {
      ...THEMES
    }
  },
  icons: iconsConfig
}
