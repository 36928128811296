<template>
  <div class="partners">
    <div class="relative mt-4">
      <h2 class="page-title">{{ $t(`itf.partner.partner`) }}<span class="page-title-link"><a :href="'/' + $i18n.locale + '/partners'">{{ $t(`itf.partner.all_partner`) }}</a></span></h2>

      <div class="partner-container">
        <div
          class="partner-container-box"
          v-for="partner of partners"
          :key="partner?.catgeory"
          :style="partner?.style"
        >
          <va-divider>
            <span class="px-2">{{ $t(`itf.partner.categories.${ partner?.catgeory }`)  }}</span>
          </va-divider>
          <div class="partner-container-box-content">
            <a
              :href="p.href"
              v-for="p of partner.children"
              :key="p.name"
              target="_blank"
            >
              <img
                class="partner-picture"
                :src="p.logo"
                :style="p.style"
                :alt="p.name"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import PARTNERS from './partners.json';

export default defineComponent({
  name: 'Partner',
  components: { },
  props: [ ],

  data () {
    const partners = PARTNERS['partners'];

    return {
      partners: partners
    }
  },
});
</script>

<style lang="scss" scoped>
  .partners {
    width: 100%;
  }

  .relative {
    position: relative;
  }

  .page-title {
    display: inline-block;
    text-transform: uppercase;
    font-size: 24px;
    border-bottom: 2px solid var(--va-primary);

    margin-top: 4px;

    &:after {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 0;
      left: 0;
      margin-top: 1px;
      border-bottom: 1px solid #eee;
      z-index: -1;
    }

    a {
      color: #888;
    }

    &-link {
      font-family: 'Lato-Light';
      position: absolute;
      right: 0;
      font-size: 16px;
      top: 12px;
      color: #888;
      cursor: pointer;
      font-weight: 400;
    }
  }

  .partners {
    .partner {
      &-container {
        display: flex;
        justify-content: center;
        margin-top: 20px;

        &-box {
          position: relative;

          flex: 1;
          padding-left: 20px;
          padding-right: 20px;
          margin-top: 12px;
          margin-bottom: 12px;

          min-height: 120px;

          &:after {
            content: "";
            position: absolute;
            display: block;
            width: 1px;
            height: 82%;
            right: 0;
            top: 20%;
            background: #eee;
            margin-top: 1px;
            z-index: -1;
          }

          @media (max-width: 1280px) {
            display: none;

            &:first-child {
              display: block;
            }
          }

          &:last-child {
            &::after {
              display: none;
            }
          }

          &-content {
            display: flex;
            justify-content: center;
            align-items: center;

            height: 100%;

            .partner-picture {
              max-height: 86px;
              max-width: 120px;
              // height: 100%;
              height: 86px;
              padding-left: 10px;
              padding-right: 10px;
              margin-top: 10px;
              margin-bottom: 10px;

              object-fit: contain;
            }
          }
        }
      }
    }
  }
</style>
