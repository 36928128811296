import axios from 'axios';

import authHeader from './auth-header'

class EventService {
  getAll() {
    return axios.get(import.meta.env.VITE_APP_API_URL + '/events/all');
  }

  getActive() {
    return axios.get(import.meta.env.VITE_APP_API_URL + '/events/active');
  }

  getById(id:any) {
    return axios.get(import.meta.env.VITE_APP_API_URL + '/events/' + id);
  }

  getByIdPopulated(id:any) {
    return axios.get(import.meta.env.VITE_APP_API_URL + '/events/populated/' + id);
  }

  create(item:any) {
    const auth_header = authHeader();

    let body = Object.assign({}, item);

    delete body['_id'];

    return axios.post(import.meta.env.VITE_APP_API_URL + '/events', body, {headers: auth_header})
  }

  update(item:any) {
    const auth_header = authHeader();

    let body = Object.assign({}, item);

    delete body['_id'];

    return axios.patch(import.meta.env.VITE_APP_API_URL + '/events/'+item._id, body, {headers: auth_header});
  }
}
export default new EventService();
