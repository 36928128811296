import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import SiteLayout from '@/layout/site-layout.vue'
import FullscreenLayout from '@/layout/fullscreen-layout.vue'
import Page404Layout from '@/layout/page-404-layout.vue'

import i18n from '../i18n'
import Tr from "../i18n/translation"

const setI18N = (locale:string) => {
  switch(locale) {
    case 'de':
      i18n.global.locale = 'de';
      Tr.switchLanguage('de')
      break;
    case 'en':
      i18n.global.locale = 'en';
      Tr.switchLanguage('en')
      break;
    default:
      i18n.global.locale = 'en';
      Tr.switchLanguage('en')
  }
}

const getI18nLocale = () : string => {
  /*
  if(localStorage.getItem('locale')) {
    setI18N(localStorage.getItem('locale')!);

    return i18n.global.locale
  } else {
    let userLang = navigator.language;

    if(userLang.includes('de')) {
      i18n.global.locale = 'de'
      localStorage.setItem('locale', i18n.global.locale)
      return i18n.global.locale
    }

    if(userLang.includes('en')) {
      i18n.global.locale = 'en'
      localStorage.setItem('locale', i18n.global.locale)
      return i18n.global.locale
    }
  }
  */

  return i18n.global.locale;
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: SiteLayout,
    beforeEnter: Tr.routeMiddleware,
  },
  {
    path: '/fullscreen',
    component: FullscreenLayout,
    children: [
      {
        name: 'oop',
        path: 'oop',
        component: () => import('@/pages/embed/order-of-play/OrderOfPlayCompleteOverview.vue'),
      },
    ]
  },
  {
    path: '/:locale',
    component: SiteLayout,
    /*
    beforeEnter: (to:any, from:any, next:any) => {
      const locale = to.params.locale.toString();

      console.log(locale)

      const supported_locales = import.meta.env.VITE_APP_I18N_SUPPORTED_LOCALE?.split(',');

      console.log(supported_locales)

      let userLang = navigator.language;

      if (!supported_locales?.includes(locale)) {
        if(userLang.includes('de')) {
          i18n.global.locale = 'de'
          localStorage.setItem('locale', i18n.global.locale)
          return next('de' + to.path)
        }

        if(userLang.includes('en')) {
          i18n.global.locale = 'en'
          localStorage.setItem('locale', i18n.global.locale)
          return next('en' + to.path)
        }

        return next('en')
      }

      console.log(getI18nLocale())

      if (i18n.global.locale !== locale) {
        i18n.global.locale = locale;
      }
      
      setI18N(locale)

      return next()
    },
    */
    beforeEnter: Tr.routeMiddleware,
    children: [
      {
        name: '404',
        path: '404',
        component: () => import('@/pages/site/404/404.vue'),
      },
      {
        name: 'home',
        path: '',
        component: () => import('@/pages/site/home/Home.vue'),
      },

      {
        name: 'news',
        path: 'news',
        component: () => import('@/pages/site/news/News.vue'),
      },
      {
        name: 'article/:aid',
        path: 'article/:aid',
        component: () => import('@/pages/site/article/Article.vue'),
      },
      {
        name: 'articles',
        path: 'articles',
        component: () => import('@/pages/site/article/Articles.vue'),
      },
      {
        name: 'photos',
        path: 'photos',
        component: () => import('@/pages/site/news/News.vue'),
      },
      {
        name: 'videos',
        path: 'videos',
        component: () => import('@/pages/site/news/News.vue'),
      },

      {
        name: 'draws',
        path: 'tournament/draws',
        component: () => import('@/pages/site/draws/Draws.vue'),
      },
      {
        name: 'order-of-play',
        path: 'tournament/order-of-play',
        component: () => import('@/pages/site/order-of-play/OrderOfPlay.vue'),
      },
      {
        name: 'live-court',
        path: 'tournament/live-court',
        component: () => import('@/pages/site/live-court/LiveCourt.vue'),
      },
      {
        name: 'downloads',
        path: 'tournament/downloads',
        beforeEnter: (to:any, from:any, next:any) => {
          const locale = to.params.locale.toString();

          if(locale == 'en') {
            return next()
          } else {
            return next()
          }
        },
        component: () => import('@/pages/site/article/ArticleSite.vue'),
        props: { category: 'downloads' }
      },
      {
        name: 'info',
        path: 'tournament/info',
        beforeEnter: (to:any, from:any, next:any) => {
          const locale = to.params.locale.toString();

          if(locale == 'en') {
            return next()
          } else {
            return next()
          }
        },
        component: () => import('@/pages/site/article/ArticleSite.vue'),
        props: { category: 'tournament-info' }
      },

      {
        name: 'champions',
        path: 'champions',
        component: () => import('@/pages/site/champions/Champions.vue'),
      },

      {
        name: 'veranstaltungsort',
        path: 'veranstaltungsort',
        beforeEnter: (to:any, from:any, next:any) => {
          const locale = to.params.locale.toString();

          if(locale == 'en') {
            return next('en/venue')
          } else {
            return next()
          }
        },
        component: () => import('@/pages/site/article/ArticleSite.vue'),
        props: { category: 'venue' }
      },
      {
        name: 'venue',
        path: 'venue',
        beforeEnter: (to:any, from:any, next:any) => {
          const locale = to.params.locale.toString();

          if(locale == 'de') {
            return next('de/veranstaltungsort')
          } else {
            return next()
          }
        },
        component: () => import('@/pages/site/article/ArticleSite.vue'),
        props: { category: 'venue' }
      },


      {
        name: 'parkmoeglichkeiten',
        path: 'parkmoeglichkeiten',
        beforeEnter: (to:any, from:any, next:any) => {
          const locale = to.params.locale.toString();

          if(locale == 'en') {
            return next('en/parking')
          } else {
            return next()
          }
        },
        component: () => import('@/pages/site/article/ArticleSite.vue'),
        props: { category: 'parking' }
      },
      {
        name: 'parking',
        path: 'parking',
        beforeEnter: (to:any, from:any, next:any) => {
          const locale = to.params.locale.toString();

          if(locale == 'de') {
            return next('de/parkmoeglichkeiten')
          } else {
            return next()
          }
        },
        component: () => import('@/pages/site/article/ArticleSite.vue'),
        props: { category: 'parking' }
      },

      {
        name: 'akkreditierung',
        path: 'akkreditierung',
        beforeEnter: (to:any, from:any, next:any) => {
          const locale = to.params.locale.toString();

          if(locale == 'en') {
            return next('en/accreditation')
          } else {
            return next()
          }
        },
        component: () => import('@/pages/site/accreditation/Accreditation.vue'),
      },
      {
        name: 'accreditation',
        path: 'accreditation',
        beforeEnter: (to:any, from:any, next:any) => {
          const locale = to.params.locale.toString();

          if(locale == 'de') {
            return next('de/akkreditierung')
          } else {
            return next()
          }
        },
        component: () => import('@/pages/site/accreditation/Accreditation.vue'),
      },

      {
        name: 'hospitality',
        path: 'hospitality',
        component: () => import('@/pages/site/hospitality/Hospitality.vue'),
      },

      {
        name: 'dienst',
        path: 'dienst',
        component: () => import('@/pages/site/service/Service.vue'),
      },
      {
        name: 'dienst/kontakt',
        path: 'dienst/kontakt',
        beforeEnter: (to:any, from:any, next:any) => {
          const locale = to.params.locale.toString();

          if(locale == 'en') {
            return next()
          } else {
            return next()
          }
        },
        component: () => import('@/pages/site/article/ArticleSite.vue'),
        props: { category: 'dienst-kontakt' }
      },

      {
        name: 'partners',
        path: 'partners',
        component: () => import('@/pages/site/partners/Partners.vue'),
      },

      {
        name: 'impressum',
        path: 'impressum',
        beforeEnter: (to:any, from:any, next:any) => {
          const locale = to.params.locale.toString();

          if(locale == 'en') {
            return next('en/imprint')
          } else {
            return next()
          }
        },
        component: () => import('@/pages/site/article/ArticleSite.vue'),
        props: { category: 'imprint' }
      },
      {
        name: 'imprint',
        path: 'imprint',
        beforeEnter: (to:any, from:any, next:any) => {
          const locale = to.params.locale.toString();

          if(locale == 'de') {
            return next('de/impressum')
          } else {
            return next()
          }
        },
        component: () => import('@/pages/site/article/ArticleSite.vue'),
        props: { category: 'imprint' }
      },

      {
        name: 'datenschutz',
        path: 'datenschutz',
        beforeEnter: (to:any, from:any, next:any) => {
          const locale = to.params.locale.toString();

          if(locale == 'en') {
            return next('en/privacy-policy')
          } else {
            return next()
          }
        },
        component: () => import('@/pages/site/article/ArticleSite.vue'),
        props: { category: 'privacy-policy' }
      },
      {
        name: 'privacy-policy',
        path: 'privacy-policy',
        beforeEnter: (to:any, from:any, next:any) => {
          const locale = to.params.locale.toString();

          if(locale == 'de') {
            return next('de/datenschutz')
          } else {
            return next()
          }
        },
        component: () => import('@/pages/site/article/ArticleSite.vue'),
        props: { category: 'privacy-policy' }
      },

      {
        name: 'kontakt',
        path: 'kontakt',
        beforeEnter: (to:any, from:any, next:any) => {
          const locale = to.params.locale.toString();

          if(locale == 'en') {
            return next('en/contact')
          } else {
            return next()
          }
        },
        component: () => import('@/pages/site/article/ArticleSite.vue'),
        props: { category: 'contact' }
      },
      {
        name: 'contact',
        path: 'contact',
        beforeEnter: (to:any, from:any, next:any) => {
          const locale = to.params.locale.toString();

          if(locale == 'de') {
            return next('de/kontakt')
          } else {
            return next()
          }
        },
        component: () => import('@/pages/site/article/ArticleSite.vue'),
        props: { category: 'contact' }
      },
    ]
  },
  {
    path: '/404',
    component: Page404Layout,
    children: [
      {
        name: '',
        path: '',
        component: () => import('@/pages/404-pages/VaPageNotFoundSimple.vue'),
      },
      {
        name: 'not-found-advanced',
        path: 'not-found-advanced',
        component: () => import('@/pages/404-pages/VaPageNotFoundSearch.vue'),
      },
      {
        name: 'not-found-simple',
        path: 'not-found-simple',
        component: () => import('@/pages/404-pages/VaPageNotFoundSimple.vue'),
      },
      {
        name: 'not-found-custom',
        path: 'not-found-custom',
        component: () => import('@/pages/404-pages/VaPageNotFoundCustom.vue'),
      },
      {
        name: 'not-found-large-text',
        path: '/pages/not-found-large-text',
        component: () => import('@/pages/404-pages/VaPageNotFoundLargeText.vue'),
      },
    ],
  },
  {
    path: '/:catchAll(.*)',
    redirect: (to:any) => {
      return { path: '/' + getI18nLocale() + '/404' }
    },
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  //  mode: import.meta.env.VITE_APP_ROUTER_MODE_HISTORY === 'true' ? 'history' : 'hash',
  routes
})

router.beforeEach(async (to, from, next) => {

  next();
})

export default router
