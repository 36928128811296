<template>
  <div class="site-layout__navbar">
    <div class="site-layout__navbar-container">
      <div class="__mobile">
        <div class="__mobile-container" @click="show_mobile_menu()">
          <img class="menu-icon" style="max-height: 32px; margin-right: 6px;" src="@/assets/svg/menu.svg" alt="X"/>
          <div>MENU</div>
        </div>
      </div>

      <div class="__logo">
        <a :href="'/' + $i18n.locale">
          <img v-if="window_width > 620" class="logo" src="/logo.svg" alt="Logo"/>
          <img v-if="window_width <= 620" style="max-height: 56px;" class="logo" src="/icon.png" alt="Logo"/>

          <!--
          <img crossorigin="Anonymous" v-if="window_width <= 620 && logo" style="max-height: 56px;" class="logo" :src="icon" alt="Logo"/>
          <img crossorigin="Anonymous" v-if="window_width > 620 && logo" class="logo" :src="logo" alt="Logo"/>
          -->
        </a>
      </div>

      <div class="__menu">
        <div class="__menu__items">
          <div class="__menu__items-links">
            <div
              :class="active_item == item ? 'item active' : 'item'"
              v-for="item of menus"
              :key="item"
              @mouseover="menu_mouse_enter(item)"
            >
              <a>{{ item['name'] }}</a>
            </div>
          </div>
        </div>
        <div
          :class="{
            '__expand': true,
            'active': menu_show,
            'inactive': !menu_show
          }"
          @mouseleave="menu_mouse_leave()"
        >
          <div class="__expand-container">
            <div class="__expand-container-box">
              <div class="__expand-container-box-links" v-if="menu_show">
                <div
                  class="__expand-container-box-links-item"
                  v-for="item of active_item?.children"
                  :key="item"
                >
                  <!-- <router-link :to="{ name: item['route_name'], params: { locale: $i18n.locale }}">{{ item['name'] }}</router-link> -->
                  <a :href="'/' + $i18n.locale + item['href']">{{ item['name'] }}</a>
                </div>
                <a :href="'/' + $i18n.locale + active_item?.button_name?.href">
                  <div class="__expand-container-box-links-button mt-2" color="var(--va-primary)">{{ active_item?.button_name?.name }} <i class="ml-2 fa-solid fa-angle-right"></i></div>
                </a>
              </div>

              <div class="__expand-container-box-articles">
                <a
                  class="__expand-container-box-articles-item"
                  v-for="article of active_item?.articles"
                  :key="article"
                  :href="'/' + $i18n.locale + '/article/' + article[$i18n.locale]?.rel_link"
                >
                  <div class="__expand-container-box-articles-item-thumbnail">
                    <img v-if="article[$i18n.locale]?.thumbnail?.url" :src="article[$i18n.locale]['thumbnail']['url']" :alt="article[$i18n.locale]['title']"/>
                  </div>
                  <div class="__expand-container-box-articles-item-title">
                    {{ article[$i18n.locale]['title'] }}
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="__sponsors">
        <div class="__sponsors-container">
          <div class="__sponsors-container-item">
            <a href="https://www.head.com/" target="_blank"><img alt="HEAD" src="@/assets/svg/Head.svg"/></a>
          </div>

          <div class="__sponsors-container-item">
            <div style="display: flex; flex-direction: column;">
              <span>Head Cup 2024</span>

              <div class="countdown" v-if="countDownDiff > 0">
                <div class="coundown-col"><span class="countdown-time">{{ countdown_obj['d'] }}</span><span class="countdown-label">{{ $i18n.locale == 'de' ? 'Tage' :'Days' }}</span></div>
                <div class="coundown-col"><span class="countdown-time">{{ countdown_obj['h'] }}</span><span class="countdown-label">{{ $i18n.locale == 'de' ? 'Std' :'Hours' }}</span></div>
                <div class="coundown-col"><span class="countdown-time">{{ countdown_obj['m'] }}</span><span class="countdown-label">{{ $i18n.locale == 'de' ? 'Min' :'Mins' }}</span></div>
                <div class="coundown-col"><span class="countdown-time">{{ countdown_obj['s'] }}</span><span class="countdown-label">{{ $i18n.locale == 'de' ? 'Sek' :'Secs' }}</span></div>
              </div>

              <div class="countdown" v-if="countDownDiff < 0" style="display: flex; justify-content: center;">
                <div class="countdown-time">{{ $t('day') }} {{ (Math.floor((-countDownDiff) / 1000 / 60 / 60 / 24) + 1) }} - <span>live</span></div>
              </div>

              <!--<span>{{ countdown_string }}</span>-->
            </div>
          </div>
        </div>
      </div>

      <div class="__lang">
        <LangSwitcher />
      </div>
    </div>

    <div v-if="mobile_menu_show" :class="mobile_menu_show_class ? '__mobile-menu active' : '__mobile-menu'">
      <div class="__mobile-menu-container">
        <div class="__mobile-menu-container-nav">
          <div class="__mobile-menu-container-nav-tile">
            <a :href="'/' + $i18n.locale">
              <img style="max-height: 56px;" class="logo" src="@/assets/favicon_white.png" alt="Logo"/>
            </a>
          </div>

          <div
            class="__mobile-menu-container-nav-tile"
            @click="mobile_active_item = item"
            v-for="item of menus"
            :class="mobile_active_item == item ? 'item active' : 'item'"
            :key="item"
          >
            {{ item['name'] }}
          </div>
        </div>

        <div class="__mobile-menu-container-content">
          <div class="__mobile-menu-container-content-title">
            <div class="__mobile-menu-container-content-title-text">{{ mobile_active_item['name'] }}</div>
            <img class="menu-close-icon" @click="close_mobile_menu()" style="max-height: 40px; margin-right: 14px;" src="@/assets/svg/menu_close.svg" alt="X"/>
          </div>

          <div class="__mobile-menu-container-content-links">
            <div
              class="__mobile-menu-container-content-links-item"
              v-for="item of mobile_active_item?.children"
              :key="item"
            >
              <!-- <router-link :to="{ name: item['route_name'], params: { locale: $i18n.locale }}">{{ item['name'] }}</router-link> -->
              <a :href="'/' + $i18n.locale + item['href']">{{ item['name'] }}</a>
            </div>

            <a :href="'/' + $i18n.locale + mobile_active_item?.button_name?.href">
              <div class="__mobile-menu-container-content-links-button" color="var(--va-primary)">{{ mobile_active_item?.button_name?.name }} <i class="ml-2 fa-solid fa-angle-right"></i></div>
            </a>
          </div>

          <div class="__mobile-menu-container-content-articles">
            <a
              class="__mobile-menu-container-content-articles-item"
              v-for="article of mobile_active_item?.articles"
              :key="article"
              :href="'/' + $i18n.locale + '/article/' + article[$i18n.locale]?.rel_link"
            >
            	<!--
              <div class="__mobile-menu-container-content-articles-thumbnail">
                <img v-if="article[$i18n.locale]?.thumbnail?.url" :src="article[$i18n.locale]['thumbnail']['url']" :alt="article[$i18n.locale]['title']"/>
              </div>
              -->

              <div class="__mobile-menu-container-content-articles-title">
                {{ article[$i18n.locale]['title'] }}
              </div>

              <div class="__mobile-menu-container-content-articles-description">
                {{ article[$i18n.locale]['description'].substring(0, 80) }}{{ article[$i18n.locale]['description'].length > 80 ? '...' : '' }}
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div style="display: none">
      <ol v-for="links of menus" :key="links">
        <li v-for="link of links.children" :key="link"><a :href="'/' + $i18n.locale + link.href">{{ link.name }}</a></li>
      </ol>
    </div>

    <!--
    <div style="position: relative; width: 100%; display: flex; justify-content: center;">
      <div style="width: 100%; max-width: 1280px; z-index: -2; position: absolute;">
        <va-alert
          v-model="show_info"
          color="primary"
          closeable
          close-icon="info"
          class="mb-6"
          style="margin-left: 10px; margin-right: 10px;"
        >
          <template #icon>
            <va-icon name="info" />
          </template>

          <a :href="'/' + $i18n.locale + '/hospitality'">
            <b style="color: #333">
              {{ $t('alert.text') }}
            </b>
            <b>
              {{ $t('alert.link') }}
            </b>
          </a>
        </va-alert>
      </div>
    </div>
    -->

    <div v-if="menu_show" :class="menu_show ? 'overlay active' : 'overlay'" @click="menu_mouse_leave()"></div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { computed } from 'vue'
import { useColors } from 'vuestic-ui'

import { useSidebarStore } from '@/stores/sidebar.store'
import { useAuthStore } from '@/stores/auth.store'

import LangSwitcher from './components/LangSwitcher.vue'

import eventService from '@/services/event.service';
import cmsService from '@/services/cms.service';
import mediaService from '@/services/media.service';

export default defineComponent({
  components: { LangSwitcher },
  
  setup () {
    const { getColors } = useColors()
    const colors = computed(() => getColors() )

    const sidebarStore = useSidebarStore();
    const authStore = useAuthStore();

    const isSidebarMinimized = computed({
      get: () => sidebarStore.isSidebarMinimized,
      set: (value) => sidebarStore.updateSidebarCollapsedState(value)
    })

    const user = computed(() => authStore.user)

    return {
      colors,
      isSidebarMinimized,
      user,
    }
  },

  data () {
    let menus = [
      {
        name: this.$t(`itf.nav.news`),
        children: [
          { name: this.$t(`itf.nav.news`), route_name: 'news', href: '/news' },
          { name: this.$t(`itf.nav.photos`), route_name: 'photos', href: '/photos'},
          { name: this.$t(`itf.nav.videos`), route_name: 'videos', href: '/videos'},
        ],
        button_name: { name: this.$t(`itf.nav.latest_news`), route_name: 'news', href: '/news' },
        articles: []
      },
      {
        name: this.$t(`itf.nav.tournament`),
        children: [
          { name: this.$t(`itf.nav.draws`), route_name: 'draws', href: '/tournament/draws' },
          { name: this.$t(`itf.nav.order_of_play`), route_name: 'order-of-play', href: '/tournament/order-of-play' },
          { name: this.$t(`itf.nav.downloads`), route_name: 'downloads', href: '/tournament/downloads' },
          { name: this.$t(`itf.nav.infos`), route_name: 'infos', href: '/tournament/info' },
          { name: this.$t(`itf.nav.partners`), route_name: 'infos', href: '/partners' },
        ],
        button_name: { name: this.$t(`itf.nav.order_of_play_button`), route_name: 'order-of-play', href: '/tournament/order-of-play' },
        articles: []
      },
      {
        name: this.$t(`itf.nav.venue_heading`),
        children: [
          { name: this.$t(`itf.nav.venue`), route_name: 'venue', href: this.$t(`itf.nav.venue_href`) },
          { name: this.$t(`itf.nav.parking`), route_name: 'parking', href: this.$t(`itf.nav.parking_href`) },
          { name: this.$t(`itf.nav.hospitality`), route_name: 'hospitality', href: '/hospitality' },
        ],
        button_name: { name: this.$t(`itf.nav.pre_reservation`), route_name: 'hospitality', href: '/hospitality' },
        articles: []
      },
      {
        name: this.$t(`itf.nav.champions`),
        children: [
          { name: this.$t(`itf.nav.champions_singles`), route_name: 'champions', href: '/champions?singles' },
          { name: this.$t(`itf.nav.champions_doubles`), route_name: 'champions', href: '/champions?doubles' },
        ],
        button_name: { name: this.$t(`itf.nav.champions`), route_name: 'champions', href: '/champions' },
        articles: []
      },
    ];

    if(this.$i18n.locale == 'de') {
      menus.push(
        {
          name: 'Dienst',
          children: [
            { name: 'Dienst anfragen', route_name: 'dienst', href: '/dienst' },
            { name: 'Fragen / Kontakt', route_name: 'dienst', href: '/dienst/kontakt' },
          ],
          button_name: { name: 'Dienst', route_name: 'dienst', href: '/dienst' },
          articles: []
        }
      )
    }

    return {
      menu_show: false,
      active_item: null,
      menus: menus,
      countdown_string: '',
      countDownDate: null,
      countDownDiff: 0,
      countdown_obj: {d: '', h: '', m: '', s:''},
      window_width: window.innerWidth,
      mobile_menu_show: false,
      mobile_menu_show_class: false,
      mobile_active_item: menus[0],
      logo: null,
      icon: null,

      show_info: ['de', 'en', 'de404', 'en404'].includes(window.location.pathname.replaceAll('/', '')),
    }
  },

  methods: {
    menu_mouse_enter (item) {
      this.menu_show = true;
      this.active_item = item;
      this.active_item.articles = this.active_item.articles.filter((article) => { return article.languages.includes(this.$i18n.locale)})
    },

    menu_mouse_leave () {
      this.menu_show = false;
      this.active_item = null;
    },

    countdown_fn (date) {
        let now = new Date().getTime();
        this.countDownDiff = date - now;

        let days = Math.floor(this.countDownDiff / (1000 * 60 * 60 * 24));
        let hours = Math.floor((this.countDownDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        hours = ('0' + hours).slice(-2);
        let minutes = Math.floor((this.countDownDiff % (1000 * 60 * 60)) / (1000 * 60));
        minutes = ('0' + minutes).slice(-2);
        let seconds = Math.floor((this.countDownDiff % (1000 * 60)) / 1000);
        seconds = ('0' + seconds).slice(-2);

        this.countdown_obj['d'] = days;
        this.countdown_obj['h'] = hours;
        this.countdown_obj['m'] = minutes;
        this.countdown_obj['s'] = seconds;
    },

    async countdown () {
      let event = await this.getActiveEvent();
      this.countDownDate = new Date(event.startdate).getTime();

      this.countdown_fn(this.countDownDate);
      setInterval(() => {
        this.countdown_fn(this.countDownDate)
      }, 1000);
    },

    onResize () {
      this.window_width = window.innerWidth;
    },

    show_mobile_menu () {
      this.mobile_menu_show = true;
      this.mobile_menu_show_class = true;

      this.mobile_active_item = this.menus[0];
    },

    close_mobile_menu () {
      this.mobile_menu_show_class = false;

      setTimeout(() => {
        this.mobile_menu_show = false;
      }, 500)
    },

    getMedia () {
      mediaService.getActive()
        .then((res) => {
          this.logo = import.meta.env.VITE_APP_API_URL + res.data.media_logo.url;
          this.icon = import.meta.env.VITE_APP_API_URL + res.data.media_icon.url;
        }).catch((error) => {
          console.error(error)
          this.$vaToast.init({ message: 'Error while loading the page!', color: 'danger' });
        });
    },

    getActiveEvent () {
      return new Promise((resolve, reject) => {
        eventService.getActive()
          .then((data) => {
            resolve(data.data)
          }).catch((error) => {
            console.error(error)
            this.$vaToast.init({ message: 'Error while loading the page!', color: 'danger' });
            reject(404)
          });
      })
    },

    getNews () {
      cmsService.getArticlesByFilter(1, 3, ['news'], [], [this.$i18n.locale])
        .then((data) => {
          let _articles = data.data.docs;

          _articles = _articles.map((item) => {
            if('de' in item) {
              item['de'] = JSON.parse(item['de']);
            }

            if('en' in item) {
              item['en'] = JSON.parse(item['en']);
            }

            return item;
          })

          this.menus[0]['articles'] = _articles;
        }).catch((error) => {
          console.error(error)
          this.$vaToast.init({ message: 'Error while loading the page!', color: 'danger' });
        });

      cmsService.getArticlesByFilter(1, 3, ['tournament'], [], [this.$i18n.locale])
        .then((data) => {
          let _articles = data.data.docs;

          _articles = _articles.map((item) => {
            if('de' in item) {
              item['de'] = JSON.parse(item['de']);
            }

            if('en' in item) {
              item['en'] = JSON.parse(item['en']);
            }

            return item;
          })

          this.menus[1]['articles'] = _articles;
        }).catch((error) => {
          console.error(error)
          this.$vaToast.init({ message: 'Error while loading the page!', color: 'danger' });
        });

      cmsService.getArticlesByFilter(1, 3, ['venue'], [], [this.$i18n.locale])
        .then((data) => {
          let _articles = data.data.docs;

          _articles = _articles.map((item) => {
            if('de' in item) {
              item['de'] = JSON.parse(item['de']);
            }

            if('en' in item) {
              item['en'] = JSON.parse(item['en']);
            }

            return item;
          })

          this.menus[2]['articles'] = _articles;
        }).catch((error) => {
          console.error(error)
          this.$vaToast.init({ message: 'Error while loading the page!', color: 'danger' });
        });

      cmsService.getArticlesByFilter(1, 3, ['champions'], [], [this.$i18n.locale])
        .then((data) => {
          let _articles = data.data.docs;

          _articles = _articles.map((item) => {
            if('de' in item) {
              item['de'] = JSON.parse(item['de']);
            }

            if('en' in item) {
              item['en'] = JSON.parse(item['en']);
            }

            return item;
          })

          this.menus[3]['articles'] = _articles;
        }).catch((error) => {
          console.error(error)
          this.$vaToast.init({ message: 'Error while loading the page!', color: 'danger' });
        });

      if(this.menus.length > 4) {
        cmsService.getArticlesByFilter(1, 3, ['dienst'], [], [this.$i18n.locale])
          .then((data) => {
            let _articles = data.data.docs;

            _articles = _articles.map((item) => {
              if('de' in item) {
                item['de'] = JSON.parse(item['de']);
              }

              return item;
            })

            this.menus[4]['articles'] = _articles;
          }).catch((error) => {
            console.error(error)
            this.$vaToast.init({ message: 'Error while loading the page!', color: 'danger' });
          });
      }
    },
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.onResize);
  },

  beforeMount () {
    this.getMedia();

    this.countdown();
  },

  mounted() {
    this.getNews();

    setTimeout(() => {
      window.addEventListener('resize', this.onResize);
    }, 100)
  },
})
</script>

<style lang="scss" scoped>
  .coundown-col {
    display: flex;
    flex-direction: column;

    width: calc(100% / 4);
  }

  .countdown-time {
    width: auto !important;
    font-size: 14px;

    color: #242424 !important;
  }

  .countdown-label {
    // font-size: 9px;
    // font-weight: 600;
    // line-height: 4px !important;

    font-size: 9px;
    font-weight: 600;
    line-height: 8px !important;
    font-family: 'Lato-Bold';
    color: #838383;
  }

  .countdown {
    display: flex;

    margin-top: 2px;

    margin-left: 8px;
    margin-right: 8px;

    &-col {
      display: flex;
      flex-direction: column;


    }
  }

  .__mobile-menu {
    position: absolute;
    top: 0;
    // left: 0;
    height: 100vh;
    width: 100vw;
    background: #fff;

    transform: translateX(-100vw);
    animation: slide-out 0.5s;

    &.active {
      // left: 0;
      transform: translateX(0%);
      animation: left-slide 0.5s;
    }

    &-container {
      display: flex;
      height: 100%;

      &-nav {
        width: 100px;
        background: #33335f;

        &-tile {
          position: relative;

          cursor: pointer;

          display: flex;
          justify-content: center;
          align-items: center;

          height: 90px;

          color: #fff;
          font-family: 'Lato-Bold';

          &:after {
            content: "";
            position: absolute;
            height: 1px;
            width: 100%;
            background: #fff;

            bottom: 0;
          }

          &.active {
            background: #fff;
            color: var(--va-primary)
          }
        }
      }

      &-content {
        flex: 1;

        &-title {
          display: flex;
          align-items: center;
          height: 90px;
          border-bottom: 1px solid #eee;

          &-text {
            flex: 1;
            text-transform: uppercase;
            padding-left: 20px;
            font-size: 20px;
          }
        }


        &-articles {
          display: flex;
          flex-direction: column;

          width: 100%;
          padding-left: 20px;
          padding-right: 20px;
          padding-top: 20px;

          &-thumbnail {
            img {
              width: 200px;
            }
          }

          &-item {
            margin-top: 20px;
          }

          &-title {
            font-size: 16px;
            color: var(--va-primary);
            font-family: 'Lato-Bold';
            padding-bottom: 8px;
          }

          &-description {
            color: #242424;
          }
        }

        &-links {
          display: flex;
          flex-direction: column;

          width: 100%;
          padding-left: 20px;
          padding-top: 20px;

          &-item {
            border-bottom: 1px solid #e1e1e1;
            margin-right: 45px;
            font-size: 1.2em;
            color: #242424;

            &:hover {
              a {
                color: var(--va-primary);
              }
            }

            a {
              color: #242424;
              display: block;
              padding: 8px 0;
              font-size: 20px;
              line-height: 28px;
            }
          }

          &-button {
            width: fit-content;
            cursor: pointer;

            background-color: var(--va-primary);
            color: #fff;

            padding: 6px 12px;
            margin-top: 20px;

            font-size: 16px;
            text-transform: uppercase;

            border-radius: 2px;
          }
        }
      }
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;

    height: 100vh;

    // height: calc( 100vh - 400px);
    // top: 400px;

    z-index: -2;

    opacity: 0;
    background: #000;

    animation: opacity-change 2s reverse;

    &.active {
      opacity: .2;
      animation: opacity-change 2s;
    }

    @media (max-width: 1024px) {
      display: none;
    }
  }

  .site-layout__navbar {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 900;

    &-container {
      max-width: 1280px;
      max-height: 94px;
      height: 94px;
      margin: 0 auto;
      display: flex;
      flex-flow: row wrap;
      padding-top: 17px;
      border-bottom: 1px solid #e1e1e1;
      background: #fff;

      @media (max-width: 1024px) {
        padding-top: 0;
      }

      .__mobile {
        display: flex;
        align-items: center;
        padding-left: 20px;
        font-size: 20px;

        cursor: pointer;

        width: 80px;

        @media (min-width: 1024px) {
          display: none;
        }

        &-container {
          display: flex;
          align-items: center;
        }
      }

      .__logo {
        @media (max-width: 1024px) {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        a {
          .logo {
            height: 76px;
            vertical-align: middle;
            margin-right: 5px;
            object-fit: contain;

            @media (max-width: 620px) {
              width: 100%;
              max-height: 76px;
              max-width: 62px;
              height: auto;
            }
          }
        }
      }

      .__menu {
        display: flex;
        flex: 1 0 auto;
        align-items: center;

        @media (max-width: 1024px) {
          display: none;
        }

        .__expand {
          width: 100%;
          min-height: 400px;
          background: #fff;
          position: absolute;
          left: 0;
          top: 0;
          z-index: -1;

          padding: 124px 0 30px;

          animation: slide-in-reverse 0.5s;

          &.inactive {
            transform: translateY(-100%);
          }

          &.active {
            animation: slide-in 0.5s;
            transform: translateY(0%);
          }

          &-container {
            width: calc(99% - 90px);
            max-width: 1190px;
            display: flex;
            position: relative;
            margin: 0 auto;
            background: #fff;
            padding-left: 70px;
            text-align: left;

            &-box {
              width: 100%;
              display: flex;

              &-links {
                display: flex;
                flex-direction: column;
                width: 25%;

                &-item {
                  border-bottom: 1px solid #e1e1e1;
                  margin-right: 45px;
                  font-size: 1.2em;
                  color: #242424;

                  &:hover {
                    a {
                      color: var(--va-primary);
                    }
                  }

                  a {
                    color: #242424;
                    display: block;
                    padding: 8px 0;
                    font-size: 16px;
                    font-weight: 400;
                    // font-family: 'Lato';
                  }
                }

                &-button {
                  width: fit-content;
                  cursor: pointer;

                  background-color: var(--va-primary);
                  color: #fff;

                  padding: 6px 12px;

                  font-size: 14px;
                  text-transform: uppercase;

                  border-radius: 2px;
                }
              }

              &-articles {
                display: flex;

                &-item {
                  width: 260px;
                  height: 180px;
                  margin-right: 20px;

                  color: #242424 !important;

                  cursor: pointer;

                  &:hover {
                    .__expand-container-box-articles-item-title {
                      color: var(--va-primary)
                    }
                  }

                  &-thumbnail {
                    width: 100%;
                    height: 100%;

                    img {
                      width: 100%;
                      height: 100%;
                      object-fit: cover;

                      border: 1px solid #eee
                    }
                  }

                  &-title {
                    font-family: 'Lato-Bold';
                    font-size: 16px;
                    line-height: 18px;
                    margin-top: 12px;
                  }
                }
              }
            }
          }
        }

        &__items {
          height: 100%;
          flex: auto;
          display: flex;
          flex-direction: row;
          align-items: center;

          margin: auto;
          max-width: 600px;

          &-links {
            width: 100%;
            height: 100%;
            display: flex;

            .item {
              flex: 1 1 auto;
              font-size: 15px;
              font-family: 'Lato-Bold';
              text-transform: uppercase;
              height: 100%;
              text-align: center;

              display: flex;
              justify-content: center;
              align-items: center;

              cursor: pointer;

              position: relative;

              &.active {
                a {
                  color: var(--va-primary);
                }

                &:before {
                  content: "";
                  width: 60%;
                  height: 4px;
                  background: var(--va-primary);
                  position: absolute;
                  bottom: 0;
                  border-radius: 4px;
                }
              }

              a {
                display: inline-block;
                height: 50px;
                top: -14px;
                line-height: 71px;
                max-height: 76px;
                transition: all .2s ease;
                position: relative;
                padding-top: 2px;
                box-sizing: border-box;
                color: #242424;
                font-family: 'Lato-Bold';

                width: 100%;
              }
            }
          }
        }
      }

      .__sponsors {
        display: flex;
        justify-content: center;
        align-items: center;

        position: relative;
        padding: 12px;

        @media (max-width: 1024px) {
          display: none;
        }

        &-container {
          position: relative;
          display: flex;

          &-item {
            display: flex;
            justify-content: center;
            align-items: center;

            position: relative;
            padding-left: 12px;
            padding-right: 12px;

            span {
              text-align: center;
              line-height: 18px;

              &:first-child {
                font-family: 'Lato-Bold';
                color: var(--va-primary);
                width: 140px;
              }
            }

            img {
              width: 86px;
            }

            &:after {
              content: "";
              position: absolute;
              right: 0;
              top: 50%;
              width: 0;
              height: 30px;
              border-right: 1px solid #e1e1e1;
              transform: translateY(-50%);
            }
          }
        }
      }

      .__lang {
        width: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .va-navbar {
    box-shadow: var(--va-box-shadow);
    z-index: 2;
    &__center {
      @media screen and (max-width: 1200px) {
        .app-navbar__github-button {
          display: none;
        }
      }
      @media screen and (max-width: 950px) {
        .app-navbar__text {
          display: none;
        }
      }
    }

    @media screen and (max-width: 950px) {
      .left {
        width: 100%;
      }
      .app-navbar__actions {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .left {
    display: flex;
    align-items: center;
    & > * {
      margin-right: 1.5rem;
    }
    & > *:last-child {
      margin-right: 0;
    }
  }

  .x-flip {
    transform: scaleX(-100%);
  }

  .va-navbar__item {
    cursor: pointer;
  }

  .app-navbar__text > * {
    margin-right: 0.5rem;
    &:last-child {
      margin-right: 0;
    }
  }

  @keyframes left-slide {
    0% {
      transform: translateX(-100vw);
    }
    100% {
      transform: translateX(0%);
    }
  }

  @keyframes slide-in {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0%);
    }
  }

  @keyframes slide-in-reverse {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-100%);
    }
  }

  @keyframes slide-out {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100vw);
    }
  }

  @keyframes opacity-change {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.2;
    }
  }
</style>
