import { ref, computed } from "vue"

import { useColors } from 'vuestic-ui'

export const THEMES = {
  light: {
    primary: '#5eb991',
    secondary: '#767c88',
    background: '#FFFFFF',
    success: '#3d9209',
    info: '#2c82e0',
    danger: '#e42222',
    warning: '#ffd43a',

    backgroundPrimary: '#FFFFFF',
    backgroundSecondary: '#FFFFFF',
    backgroundElement: '#f6f7f6',
    backgroundBorder: '#acb5be',

    textPrimary: '#262824',
    textInverted: '#ffffff',

    shadow: 'rgba(0, 0, 0, 0.12)',
    focus: '#ffff00',

    white: '#FFFFFF',
    dark: '#262824',
    gray: '#767c88',
  },
  dark: {
    primary: '#51f29a',
    secondary: '#767c88',

    background: '#000037',

    success: '#3d9209',
    info: '#2c82e0',
    danger: '#e42222',
    warning: '#ffd43a',

    backgroundPrimary: '#000037',
    backgroundSecondary: '#33335f',

    backgroundElement: '#000037',
    backgroundBorder: '#acb5be',

    textPrimary: '#FFFFFF',
    textInverted: '#FFFFFF',

    shadow: 'rgba(0, 0, 0, 0.12)',
    focus: '#ffff00',

    white: '#FFFFFF',
    dark: '#FFFFFF',
    gray: '#767c88',
  }
}

/*
        primary: '#5eb991',
        secondary: '#767c88',
        background: '#f6f7f6',
        success: '#3d9209',
        info: '#2c82e0',
        danger: '#e42222',
        warning: '#ffd43a',

        backgroundPrimary: '#f6f6f6',
        backgroundSecondary: '#FFFFFF',
        backgroundElement: '#f6f7f6',
        backgroundBorder: '#acb5be',

        textPrimary: '#262824',
        textInverted: '#ffffff',

        shadow: 'rgba(0, 0, 0, 0.12)',
        focus: '#ffff00',

        white: '#FFFFFF',
        dark: '#262824',
        gray: '#767c88',

        ###########
        
        primary: '#51f29a',
        secondary: '#767c88',

        background: '#000037',

        success: '#3d9209',
        info: '#2c82e0',
        danger: '#e42222',
        warning: '#ffd43a',

        backgroundPrimary: '#000037',
        backgroundSecondary: '#33335f',

        backgroundElement: '#f6f7f6',
        backgroundBorder: '#acb5be',

        textPrimary: '#FFFFFF',
        textInverted: '#262824',

        shadow: 'rgba(0, 0, 0, 0.12)',
        focus: '#ffff00',

        white: '#262824',
        dark: '#FFFFFF',
        gray: '#767c88',

*/